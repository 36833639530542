import React from "react";
import styled from "styled-components";
import themeStyles from "../styles/theme.styles";

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #fff;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${themeStyles?.PRIMARY_COLOR};

  img {
    height: 40px;
    margin-right: 10px;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 30px;
`;

const NavLink = styled.a`
  color: #666;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    color: ${themeStyles?.PRIMARY_COLOR};
  }
`;

const SignUpButton = styled.a`
  background-color: #f5f5f5;
  color: ${themeStyles?.PRIMARY_COLOR};
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    background-color: ${themeStyles?.SECONDARY_COLOR};
    color: ${"#FFF"};
  }
`;

const Header = () => (
  // <HeaderContainer>

  <div className="flex flex-row justify-between items-center px-20 py-2">
    <Logo>
      <img src="assets/carecrew.png" alt="CareCrew Logo" />
    </Logo>
    <NavLinks className="h-full">
      <NavLink href="#home">Home</NavLink>
      <NavLink href="#get-in-touch">Get in Touch</NavLink>
      <NavLink href="#support">Support</NavLink>
    </NavLinks>
    <SignUpButton href="#signup">Sign up</SignUpButton>
  </div>
  // </HeaderContainer>
);

export default Header;
