import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-8">
      <div className="container mx-auto px-6 lg:px-20">
        <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
          <div className="mb-8 lg:mb-0">
            <img
              src="/assets/carecrew.png"
              alt="CareCrew Logo"
              className="w-[200px]"
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 lg:gap-20 text-gray-600">
            <div>
              <h5 className="font-semibold mb-4">Products</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Storytale
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold mb-4">Categories</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Pro Access
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold mb-4">Company</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    Become an Author
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="font-semibold mb-4">Info</h5>
              <ul>
                <li>
                  <a href="#" className="hover:underline">
                    License
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t pt-6 flex flex-col lg:flex-row justify-between items-center text-center lg:text-left text-gray-600">
          <p>&copy; CareCrew. All rights reserved. &copy; 2023</p>
          <div className="flex space-x-4 mt-4 lg:mt-0">
            <a href="#" className="hover:underline">
              <FontAwesomeIcon
                icon={["fab", "twitter"]}
                className="h-6 text-gray-600"
              />
            </a>
            <a href="#" className="hover:underline">
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                className="h-6 text-gray-600"
              />
            </a>
            <a href="#" className="hover:underline">
              <FontAwesomeIcon
                icon={["fab", "facebook"]}
                className="h-6 text-gray-600"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
