import React from "react";

const GetCareCrewSection = () => {
  return (
    <section className=" bg-PRIMARY_COLOR text-white rounded-xl">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-10">
        <div className="lg:w-1/2 mb-10 lg:mb-0">
          <h2 className="text-4xl font-bold mb-4">Get CareCrew Now!</h2>
          <p className="text-lg mb-6">
            Personal performance tracking made easy.
          </p>
          <div className="flex space-x-4">
            <a href="#">
              <img
                src="/assets/apple_download.png"
                alt="App Store"
                // className="h-12"
              />
            </a>
            <a href="#">
              <img
                src="/assets/play_download.png"
                alt="Google Play"
                // className="h-12"
              />
            </a>
          </div>
        </div>
        <div className="flex justify-center">
          <img
            src="/assets/get_carecrew_now_content.png"
            alt="CareCrew App"
            className="h-full w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default GetCareCrewSection;
