import React from "react";
import styled from "styled-components";
import themeStyles from "../styles/theme.styles";

const TestimonialsContainer = styled.section`
  padding: 60px 20px;
  background-color: #f8f9fa;
  text-align: center;
`;

const TestimonialsTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
  color: #333;
`;

const TestimonialsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const TestimonialCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33% - 40px);
  max-width: calc(33% - 40px);
  min-width: 280px;
  text-align: left;
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  color: #666;
`;

const TestimonialAuthor = styled.p`
  font-size: 1rem;
  color: ${themeStyles?.PRIMARY_COLOR};
  font-weight: bold;
  margin-top: 10px;
`;

const TestimonialAuthorInfo = styled.p`
  font-size: 0.875rem;
  color: #666;
`;

const TestimonialsSection = () => (
  <TestimonialsContainer id="testimonials">
    <TestimonialsTitle>What Our Users Say</TestimonialsTitle>
    <TestimonialsGrid>
      <TestimonialCard>
        <TestimonialText>
          "I have been using CareCrew for a year now. Everything is detailed and
          well organized and, of course, damn beautiful."
        </TestimonialText>
        <TestimonialAuthor>John Doe</TestimonialAuthor>
        <TestimonialAuthorInfo>
          Nurse Manager, ABC Hospital
        </TestimonialAuthorInfo>
      </TestimonialCard>
      <TestimonialCard>
        <TestimonialText>
          "CareCrew has revolutionized our scheduling and task management. It's
          a must-have tool for any healthcare team."
        </TestimonialText>
        <TestimonialAuthor>Jane Smith</TestimonialAuthor>
        <TestimonialAuthorInfo>Head Nurse, XYZ Clinic</TestimonialAuthorInfo>
      </TestimonialCard>
      <TestimonialCard>
        <TestimonialText>
          "The efficiency and ease of use are unparalleled. Our team is now more
          coordinated and productive."
        </TestimonialText>
        <TestimonialAuthor>Michael Brown</TestimonialAuthor>
        <TestimonialAuthorInfo>
          HR Manager, 123 Healthcare
        </TestimonialAuthorInfo>
      </TestimonialCard>
    </TestimonialsGrid>
  </TestimonialsContainer>
);

export default TestimonialsSection;
