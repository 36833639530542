import React from "react";
import styled from "styled-components";

const VideoContainer = styled.section`
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
`;

const VideoTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
`;

const VideoSubtitle = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 40px;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a2540;
  padding: 40px;
  border-radius: 20px;
`;

const Video = styled.div`
  width: 100%;
  // max-width: 600px;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
`;

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
`;

const VideoSection = () => (
  <VideoContainer>
    <VideoTitle>Discover the Power of CrewCare: A Visual Tour</VideoTitle>
    <VideoSubtitle>
      Uncover the Features, Benefits, and Success Stories
    </VideoSubtitle>
    {/* <VideoWrapper> */}
    <Video>
      <VideoFrame
        src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Placeholder video URL
        title="Video tour"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Video>
    {/* </VideoWrapper> */}
  </VideoContainer>
);

export default VideoSection;
