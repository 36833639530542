import React from "react";
import styled from "styled-components";
import themeStyles from "../styles/theme.styles";

const HeroContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 20px;
  background-color: #fff;
`;

const HeroContent = styled.div`
  max-width: 50%;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  color: #333;
`;

const HeroHighlight = styled.span`
  color: ${themeStyles?.PRIMARY_COLOR};
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin: 20px 0;
`;

const HeroButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const HeroButton = styled.button`
  background-color: ${(props) =>
    props.primary ? themeStyles?.PRIMARY_COLOR : "#fff"};
  color: ${(props) => (props.primary ? "#fff" : themeStyles?.PRIMARY_COLOR)};
  padding: 10px 20px;
  border: 2px solid ${themeStyles?.PRIMARY_COLOR};
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#e6e6e6")};
  }
`;

const HeroImage = styled.div`
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaceholderImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #666;
`;

const HeroSection = () => (
  <HeroContainer>
    <HeroContent>
      <HeroTitle>
        Empower Your Healthcare Team with Seamless{" "}
        <HeroHighlight>Shift Management</HeroHighlight>
      </HeroTitle>
      <HeroSubtitle>
        Our intuitive platform empowers healthcare facilities to connect with
        staff, optimize staffing and improve work-life balance for nurses.
      </HeroSubtitle>
      <HeroButtons>
        <HeroButton primary>Sign up for an invite </HeroButton>
        <HeroButton>Book A Free Demo</HeroButton>
      </HeroButtons>
    </HeroContent>
    {/* <HeroImage>
      <PlaceholderImage>Image Placeholder</PlaceholderImage>
    </HeroImage> */}
    <HeroImage>
      <img
        src="/assets/HeroImage.png"
        alt="Hero"
        style={{ width: "100%", height: "auto" }}
      />
    </HeroImage>
  </HeroContainer>
);

export default HeroSection;
