import React from "react";

const ShiftManagementSection = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-blue-50 to-white">
      <div className="container mx-auto px-6 text-center">
        <div className="mb-12">
          <a href="#" className="text-sm text-blue-600 uppercase">
            Join Us Now
          </a>
          <h2 className="text-4xl font-bold text-gray-900 my-2">
            Shift to Management with CareCrew
          </h2>
          <p className="text-lg text-gray-700">
            Effortless Staff Scheduling and Collaboration for Healthcare Heroes
          </p>
        </div>
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-md p-6  flex items-center">
            <div className="text-left">
              <div className="mb-4">
                <span className="inline-block bg-green-100 text-green-700 px-3 py-1 rounded-full text-sm">
                  New
                </span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Efficient Shift Management
              </h3>
              <p className="text-lg text-gray-700 mb-4">
                Revolutionize Your Clinic's Workflow with our Intuitive Web
                Portal
              </p>
              <p className="text-gray-600 mb-6">
                Streamline scheduling, manage staff assignments, and optimize
                resources seamlessly with our comprehensive web-based shift
                management platform.
              </p>
              <p className="text-gray-600 mb-6">
                Say goodbye to scheduling hassles and hello to streamlined
                operations.
              </p>
              <a href="#" className="text-blue-500 font-semibold">
                Learn more →
              </a>
            </div>
            <img
              src="/assets/Browser.png"
              alt="Dashboard"
              className="rounded-lg ml-6"
            />
          </div>
          <div className="flex flex-row gap-8">
            <div className="bg-blue-900 text-white rounded-lg shadow-md pt-6 px-6 flex flex-col justify-between w-1/3">
              <div>
                <h3 className="text-2xl font-bold mb-4">
                  Maximum Efficiency with Mobile
                </h3>
                <p className="text-lg mb-6">
                  Stay connected and in control no matter where you are.
                </p>
                <div className="flex space-x-4 mb-6">
                  <a href="#">
                    <img
                      src="/assets/apple_download.png"
                      alt="App Store"
                      className="h-10"
                    />
                  </a>
                  <a href="#">
                    <img
                      src="/assets/play_download.png"
                      alt="Google Play"
                      className="h-10"
                    />
                  </a>
                </div>
              </div>
              <img
                src="/assets/Phone.png"
                alt="Mobile App"
                className="rounded-lg"
              />
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between w-2/3">
              <div>
                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                  Explore the Power of Our Mobile App
                </h3>
                <p className="text-lg text-gray-700 mb-6">
                  Beyond shift management, our mobile app offers a suite of
                  versatile features designed to streamline various aspects of
                  healthcare operations. From communication tools to task
                  management, explore how our app empowers healthcare
                  professionals to deliver exceptional care efficiently.
                </p>
                <a href="#" className="text-blue-500 font-semibold">
                  View on Store →
                </a>
              </div>
              <img
                src="/assets/explore_mobile_container.png"
                alt="Mobile App Features"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShiftManagementSection;
